@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700&display=swap");

body {
	margin: 0;
	font-family: 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding: 0;
	box-sizing: content-box;
	overflow-x: auto;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

button, a {
	text-transform: none!important;
};

@keyframes flash {
	0% {opacity: 1;}
	50% {opacity: .4;}
	100% {opacity: 1;} 
}

.image-list-item button {
    margin: auto;
    display: block;
}

.MuiTabs-flexContainer { overflow: auto; }